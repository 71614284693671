<template>
  <el-form :label-width="labelwidth + 'px'" class="form" :model="form" ref="form">
    <el-row :gutter="20">
      <el-col :span="item.col" v-for="(item, index) in fields" :key="index">
        <el-form-item :key="item.field" :class="{
            block: inline === true && item.block === true,
            splitline: item.type === 'splitline' && !item.label,
            splitlinehastext: item.type === 'splitline' && !!item.label,
          }" :label="item.label" :prop="item.field" :rules="item.rules">
          <el-select v-if="item.type === 'select'" v-model="form[item.field]" :multiple="item.multiple === true"
            :filterable="item.filterable === true" :disabled="item.disabled === true"
            :clearable="item.clearable === true" @change="
              (val) =>
                $emit(
                  `change${item.field.replace(/^([a-z])/, (r) =>
                    r.toUpperCase()
                  )}`,
                  val
                )
            ">
            <el-option v-for="(option, index) in item.options" :key="index" :value="option.value" :label="option.label"
              :disabled="option.disabled"></el-option>
          </el-select>
          <el-radio-group v-else-if="item.type === 'radio'" v-model="form[item.field]">
            <el-radio v-for="(option, index) in item.options" :key="index" :label="option.value"
              :disabled="option.disabled">{{ option.label }}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-else-if="item.type === 'checkbox'" v-model="form[item.field]">
            <el-checkbox v-for="(option, index) in item.options" :key="index" :label="option.label"
              :value="option.value" :disabled="option.disabled">{{ option.label }}</el-checkbox>
          </el-checkbox-group>
          <el-input clearable v-else-if="item.type === 'input'" v-model="form[item.field]"
            :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon" :placeholder="item.placeholder"
            :readonly="item.onlyread === true" :disabled="item.disabled === true"></el-input>
          <el-input clearable v-else-if="item.type === 'password'" type="password" v-model="form[item.field]"
            :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon" :placeholder="item.placeholder"
            :readonly="item.onlyread === true" @keyup.enter.native="$emit('submit')"></el-input>
          <el-input clearable v-else-if="item.type === 'textarea'" type="textarea" v-model="form[item.field]"
            :readonly="item.onlyread === true"></el-input>
          <el-input clearable type="number" v-else-if="item.type === 'number'" v-model="form[item.field]"
            :min="item.min || 1" :max="item.max || 10" :disabled="item.onlyread === true"></el-input>
          <el-date-picker v-else-if="
              item.type === 'daterange' || item.type === 'datetimerange'
            " v-model="form[item.field]" :type="item.type" range-separator="~" :disabled="item.disabled === true"
            :readonly="item.onlyread === true" :editable="item.editable === true" :clearable="item.clearable === true"
            :format="
              'yyyy-MM-dd' + (item.type === 'datetimerange' ? ' HH:mm:ss' : '')
            " :value-format="
              'yyyy-MM-dd' + (item.type === 'datetimerange' ? ' HH:mm:ss' : '')
            " :default-time="['00:00:00', '23:59:59']"></el-date-picker>
          <el-date-picker v-else-if="item.type === 'date'" type="date" v-model="form[item.field]"
            :disabled="item.disabled === true" :readonly="item.onlyread === true" value-format="yyyy-MM-dd"
            placeholder="选择日期"></el-date-picker>
          <el-date-picker v-else-if="item.type === 'datetime'" type="datetime" v-model="form[item.field]"
            :disabled="item.disabled === true" :readonly="item.onlyread === true" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"></el-date-picker>
          <el-time-select v-else-if="item.type === 'time'" v-model="form[item.field]" :disabled="item.disabled === true"
            :readonly="item.onlyread === true" value-format="HH:mm:ss" placeholder="选择时间"></el-time-select>
          <el-slider v-else-if="item.type === 'slider'" v-model="form[item.field]" :disabled="item.onlyread === true">
            {{ form[item.field] }}
          </el-slider>
          <el-cascader class="mr-20" v-else-if="item.type === 'addr'" v-model="form[item.field]" :options="optionsaddr"
            :props="cascaderProps"></el-cascader>
          <uploads @fileurl="getUrlSingle" :path="form[item.field]" @fileDel="delFileSingle" :item="item"
            v-else-if="item.type === 'file' && item.type2 !== 'more'"></uploads>

          <uploadfile-mul @fileurl="getUrl" :list="form[item.field]" @fileDel="delFile" :item="item"
            v-else-if="item.type === 'file' && item.type2 === 'more'"></uploadfile-mul>
          <uploaders @fileurl="getUrl" :list="form[item.field]" @fileDel="delFile" :item="item"
            v-else-if="item.type === 'ortherFile'"></uploaders>
          <addresss :item="item" @getadd="getAddress" :list="form[item.field]" :isDisabled="item.disabled"
            v-else-if="item.type === 'address'"></addresss>
          <el-cascader ref="cascader" v-else-if="item.type === 'cascader'" v-model="form[item.field]" :options="item.options" :props="item.props" filterable clearable></el-cascader>
          <!-- 可复用的作用域插槽用于补充当前未包含的类型 -->
          <!-- 插槽定义：<slot :name=插槽名 v-bind:父组件中接收被传递属性的属性名=子组件中要传递的属性的属性名> -->
          <!-- 插槽使用：<template v-slot:插槽名="插槽prop的对象名"></template> 或 <template v-slot:插槽名="{父组件中接收被传递属性的属性名}"></template> -->
          <slot v-else-if="item.type === 'slot'" :name="item.field" v-bind:form="form"></slot>
        </el-form-item>
        <!-- <span style="position: absolute;">ddddddddddddd</span> -->
      </el-col>

    </el-row>
    <slot name="slot" :form="form"></slot>
    <div>
      <span style="width: 100px; display: inline-block" :style="{ width: labelwidth + 'px' }"></span>
      <el-button v-for="(itemB, indexB) in btnlist" :key="indexB" :type="indexB == 0 ? 'primary' : ''"
        @click="save(indexB)">{{ itemB }}</el-button>
    </div>
  </el-form>
</template>
<script>
  import uploaders from "./uploaders.vue";
  import uploadfileMul from "./uploadFileMul.vue";
  import addresss from "./address.vue";
  export default {
    props: {
      check: {
        type: Boolean,
        default: false,
      },
      labelwidth: {
        type: String,
        default: "100",
      },
      btnlist: {
        type: Array,
        default: () => [],
      },
      width: {
        type: Number,
        default: 1,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      visible: {
        type: Boolean,
        default: false,
      },
      isfeedback: {
        type: Boolean,
        default: false,
      },
      dataId: [String, Number],
      fields: {
        type: Array,
        default: () => [],
        // 示例 [{ field: 'name', label: '名称', type: 'input', ?defaultValue: '', ?rules: [], ?onlyread }]
        // { field: 'type', label: '类型', type: 'select', ?defaultValue: '', options: [{label, value, ?disabled}], ?rules: [] }
        // { field: 'enable', label: '是否启用', type: 'radio', ?defaultValue: '', options: [], ?rules: [] }
        // { field: 'industry', label: '所属行业', type: 'checkbox', ?defaultValue: [], options: [], ?rules: [] }
      },
      dataSource: Object,
      getInfoApi: Function,
      saveApi: Function,
      updateApi: Function,
    },
    data() {
      return {
        form: {},
        rules: {},
        saving: false,
      };
    },
    components: {
      addresss,
      uploadfileMul,
      uploaders,
    },
    watch: {
      fields(val) {
        console.log(val);
      },
    },
    mounted() {
      this.fields.forEach((item) => {
        if (item.default) {
          this.form[item.field] = item.default;
          this.$set(this.form, item.field, item.default);
        } else if (item.field) {
          this.form[item.field] = "";
          this.$set(this.form, item.field, "");

        }
      });

      setTimeout(() => {
        // this.$refs.form.resetFields()
      }, 700);
    },
    methods: {
      getAddress(e) {

        this.form["addr_str"] = e.data;
        this.form[e.name] = e.addr;

      },
      handleChange() { },
      delFileSingle(item) {
        this.$utils.delFileByUrl(item.name, this);
        this.form[item.name] = "";
      },
      delFile(item) {
        const url = this.form[item.name].splice(item.index, 1);
        url.length && this.$utils.delFileByUrl(url[0], this);
      },
      getUrlSingle(e) {
        this.form[e.name] = e.res;
      },
      getUrl(e) {
        if (this.form[e.name] == "" || this.form[e.name] == undefined) {
          this.form[e.name] = [];
        }
        this.form[e.name].push(e.res);

      },
      handleClose() {
        this.$emit("close", true);
      },
      save(index) {
        if (index == 1) {
          this.$emit("back", true);
          return;
        }
        if (this.saving) return;
        this.$refs.form.validate((r) => {
          if (r) {
            if (this.isfeedback) {
              // 在各自页面调用接口
              this.$emit("feed", this.form);
              return;
            }
            this.saving = true;
            if (this.dataId) {
              this.updateApi(Object.assign({ id: this.dataId }, this.form))
                .then((res) => {
                  this.saving = false;
                  if (res) {
                    this.$message.success("更新成功");
                    this.$emit("dataChange");
                  } else {
                    this.$message.error("更新失败");
                  }
                })
                .catch(() => (this.saving = false));
            } else {
              this.saveApi(Object.assign({}, this.form))
                .then((res) => {
                  this.saving = false;
                  if (res) {
                    this.$message.success("新增成功");
                    this.$emit("dataChange");
                  } else {
                    this.$message.error("新增失败");
                  }
                })
                .catch(() => (this.saving = false));
            }
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .el-date-editor.el-input,
  /deep/.el-date-editor.el-input__inner {
    width: auto;
  }

  /deep/ .el-radio {
    margin-right: 10px;
  }

  /deep/ .el-form-item__label {
    font-size: 13px;
  }

  /deep/ .el-form-item {
    font-size: 12px;
    margin-bottom: 17px;
  }
</style>